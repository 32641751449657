import React from "react"
import MarkdownPage from "components/MarkdownPage"

interface MDXLayoutProps {
  children: React.ReactNode
  pageContext: {
    frontmatter: {
      title?: string
    }
  }
}
export default function MDXLayout({
  children,
  pageContext: { frontmatter },
}: MDXLayoutProps) {
  return <MarkdownPage title={frontmatter.title}>{children}</MarkdownPage>
}
